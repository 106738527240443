import React from "react"

import SchoolHeaderSection from "../../sections/schools/SchoolHeaderSection"
import SchoolItemGallerySection from "../../sections/schools/SchoolItemGallerySection"
import SchoolFooterSection from "../../sections/schools/SchoolFooterSection"

import Layout from "../../components/Layout"
import SideNavApplyDocsSection from "../../sections/SideNavApplyDocsSection"

const SchoolPageGallery = props => {
  const schoolsSelect = props.pageContext.schools
    ? props.pageContext.schools
    : props.pageContext.summerSchools

  return (
    <Layout
      wrapperClass={"school-item gallery-item"}
      pageTitle={`${props.pageContext.currentSchool.name} | Smart Admissions Service`}
      isSecondLayout={true}
    >
      <SchoolHeaderSection
        isMainSchoolTab={false}
        state3="active"
        currentSchoolScholarships={props.pageContext.currentSchoolScholarships}
        school={props.pageContext.currentSchool}
        schoolSectionHeader="shrinked"
        schoolSectionHeaderLogo="hide"
      />
      <SchoolItemGallerySection media={props.pageContext.media || []} />
      <SchoolFooterSection
        school={props.pageContext.currentSchool}
        schools={schoolsSelect}
      />
      <SideNavApplyDocsSection school={props.pageContext.currentSchool} />
    </Layout>
  )
}

export default SchoolPageGallery
