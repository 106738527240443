import React from "react";
import { useTranslation } from "react-i18next";

const SchoolItemGalleryTitle = () => {
    const {t} = useTranslation();
    return (
        <div className="key-data-title school-info-title">{t('school.gallery.galleryTitle')}</div>
    )
}
export default SchoolItemGalleryTitle

