import React, { Component } from "react"
import Slider from "react-slick"


// import "../../styles/school-item-gallery-slider.css"

import SchoolItemGalleryTitle from "./SchoolItemGalleryTitle"

import ArrowLeftIconDark from "../../svg/arrow-left-dark.inline.svg"

import ArrowRightIconDark from "../../svg/arrow-right-dark.inline.svg"

import { Swiper, SwiperSlide } from "swiper/react"

import SwiperCore, { Pagination, Navigation } from "swiper"
// import "swiper/swiper.scss"
// import "swiper/components/navigation/navigation.scss"
// import "swiper/components/pagination/pagination.scss"
SwiperCore.use([Pagination, Navigation])

export default class SchoolItemGallerySection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      slides: props.media || [],
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    return (
      <div className="white-block about-school-block school-item-gallery-section-wrapper pb-11">
        <div className="about-school-content-wrapper f-js container">
          <SchoolItemGalleryTitle />
          {/* <!--- GALLERY SLIDER ---> */}
          {/* <div className="school-item-main-gallery-slider"> */}
          <div className="row justify-content-center add-paddings-class">
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={180}
              className="mySwiper col-12"
              pagination={{
                el: ".events-pagination",
                clickable: true,
              }}
              navigation={{
                nextEl: ".hand-book-slider-control-button.slider-right",
                prevEl: ".hand-book-slider-control-button.slider-left",
                // prevEl: navigationPrevRef.current,
                // nextEl: navigationNextRef.current,
              }}
              grabCursor={true}
            >
              {this.state.slides &&
                this.state.slides.map((el, index) => {
                  if (!el.contentType) {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className="school-item-line-gallery-block col"
                        >
                          <iframe
                            type="text/html"
                            key={el.id}
                            title="YouTube video player"
                            frameBorder="0"
                            allowFullScreen
                            style={{ height: "100vh", width: "100vh", maxWidth: "800px" }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            src={el.resolvedFilePath}
                          ></iframe>
                          <div className="school-gallery-description-line">
                            {el?.description}
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  } else {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className="school-item-line-gallery-block"
                        >
                          <img key={el.id} src={el.resolvedFilePath} alt="" />
                          <div className="school-gallery-description-line">
                            {el?.description}
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  }
                })}
            </Swiper>
            {/* <div className="events-pagination school-gallery-item upcoming-events-lines-wrapper col-12 d-flex justify-content-center mt-4"></div> */}
            <div className="row remove-paddings-class d-flex align-items-center justify-content-between">
              <div className="hand-book-slider-control-button slider-left col-2 mt-2" />
              <div className="events-pagination school-gallery-item upcoming-events-lines-wrapper col-md-8 col-lg-8 d-flex justify-content-center mt-4"></div>
              <div className="hand-book-slider-control-button slider-right col-2 mt-2" />
              {/* <div className="d-flex justify-content-center gap-4 mt-6 col col-lg-2">
                <div className="hand-book-slider-control-button slider-left" />
                <div className="hand-book-slider-control-button slider-right" />
              </div> */}
            </div>
          </div>
          {/* <Slider
              asNavFor={this.state.nav2}
              ref={slider => (this.slider1 = slider)}
              prevArrow={<SchoolGalleryPagePrevArrow />}
              nextArrow={<SchoolGalleryPageNextArrow />}
            >
              {
                this.state.slides.map((el,index) => {
                  if (!el.contentType) {
                    return (
                      <div key={index} className='school-item-line-gallery-block'>
                        <iframe
                          type="text/html"
                          key={el.id}
                          title="YouTube video player"
                          frameBorder="0"
                          allowFullScreen
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          src={el.resolvedFilePath}></iframe>
                          <div className='school-gallery-description-line'>{el?.description}</div>
                        </div>
                    )
                  }

                  return (
                    <div key={index} className='school-item-line-gallery-block'>
                      <img key={el.id} src={el.resolvedFilePath} alt="" />
                      <div className='school-gallery-description-line'>{el?.description}</div>
                    </div>

                  )
                })
              }
            </Slider> */}
          {/* </div> */}
          {/* Slider line */}
          {/* <div className="school-item-line-gallery-slider">
            <Slider
              asNavFor={this.state.nav1}
              ref={slider => (this.slider2 = slider)}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {
                this.state.slides.map((el,index) => {
                  if (!el.contentType) {
                    return (
                      <iframe
                        type="text/html"
                        key={index}
                        title="YouTube video player"
                        frameBorder="0"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        src={el.resolvedFilePath}></iframe>
                    )
                  }

                  return (
                    <img key={index} src={el.resolvedFilePath} alt="" />
                  )
                })
              }

            </Slider>
          </div> */}
        </div>
      </div>
    )
  }
}
